<template>
  <LayoutTelas id="tela__notas" :titulo="this.$route.meta.titulo">
    <MonitorForm/>
  </LayoutTelas>
  
</template>

<script>
import MonitorForm from "./forms/MonitorForm";

export default {
  data() {
    return {
      dataInventario: '',
    }
  },
  components: {
    MonitorForm
  }
  
}
</script>

<style lang="scss" scoped>
@import "@/common/scss/breakpoints.scss";

#tela__notas {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  @media (min-width: $medium-size) {
    min-width: $small-size;
  }
}

.paginacao {
  display: flex;
  justify-content: flex-end;

  &__seletor {
    margin: 0;
  }
}
</style>