<template>
  <div>
    <loading
      :active.sync="dados.isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="#f68b29"
    />
    <div class="filtro">
      <InputSelect
        ref="refTipo"
        label="Tipo"
        name="tipo"
        size="sm"
        :options="getTipo()"
        class="inputTipo"
        v-model="form.tipo"
        :disabled="dados.disabledCampos || dados.checked"
      />
      <InputSelect
        ref="refEvento"
        label="Evento"
        name="evento"
        :options="getEvento()"
        class="inputEvento"
        size="sm"
        v-model="form.evento"
        :disabled="dados.disabledCampos || dados.checked"
      />
      <InputSelect
        ref="refStatus"
        label="Status"
        name="status"
        :options="getStatus()"
        class="inputStatus"
        size="sm"
        v-model="form.status"
        :disabled="dados.disabledCampos || dados.checked"
      />
      <InputBasico
        ref="refNota"
        label="Nota"
        name="nota"
        v-model="form.nota"
        min="1"
        max="12"
        class="inputNota"
        size="sm"
        :disabled="dados.disabledCampos || dados.checked"
      />
      <InputBasico
        ref="refSerie"
        label="Série"
        name="serie"
        v-model="form.serie"
        class="inputSerie"
        size="sm"
        :disabled="dados.disabledCampos || dados.checked"
      />
      <InputBasico
        ref="refCNPJ"
        label="CNPJ"
        name="cnpj"
        v-model="form.cnpj"
        min="1"
        max="12"
        class="inputCNPJ"
        size="sm"
        :disabled="dados.disabledCampos || dados.checked"
      />

      <b-button size="sm" variant="primary" style="margin-bottom:0.5rem" @click="buscarFiltro()">Buscar</b-button>
      <b-button size="sm" variant="outline-secondary" style="margin-bottom:0.5rem" @click="limparFiltro()">Limpar</b-button>
      <b-button 
        size="sm" 
        variant="danger" 
        style="margin-bottom:0.5rem"
        v-if="validaRole()"
        v-b-modal.inutilizacao 
        @click="abrirEnvioInutilizacao()">
          Inutilizar
      </b-button>

      <div class="listaNotas">
        <b-table
          striped
          hover
          bordered
          small
          stacked="md"
          head-variant="dark"
          v-if="dados.itemProcessamento"
          :items="dados.itemProcessamento"
          :fields="dados.fieldsNotas"
          @row-clicked="mostrarRespostaEdocs"
          :tbody-tr-class="rowClass">

          <template v-slot:cell(tipo)="data">
            {{data.item.nota.IS_NFE_IDE || !data.item.nota.numnfs || (data.item.eventoNota == "CANCELAMENTO" && data.item.nota.IV_N_NF)? "Produto" : "Serviço"}}
          </template>

          <template v-slot:cell(eventoNota)="data">
            {{getEvento().filter(e => e.value == data.item.eventoNota)[0]?getEvento().filter(e => e.value == data.item.eventoNota)[0].text:""}}
          </template>

          <template v-slot:cell(status)="data">
            {{getStatus().filter(s => s.value == data.item.status)[0].text}}
          </template>

          <template v-slot:cell(serieNota)="data">
            {{retornaSerie(data.item)}}
          </template>

          <template v-slot:cell(numeroNota)="data">
            {{retornaNota(data.item)}}
          </template>

          <template v-slot:cell(xmlGerado)="data" v-if="validaRole()">
            <b-button size="sm" variant="info" v-b-modal.xml @click="getXML(data.item.nota)">
              <v-icon scale="1" name="file-excel" />
            </b-button>
          </template>

          <template v-slot:head(status) v-if="validaRole()">
            Status
            <b-spinner
              v-if="!dados.isLoading"
              small
              type="grow"
              label="Loading..."
              variant="success"></b-spinner>
          </template>

          <template v-slot:cell(notaSapiens)="data" v-if="validaRole()">
            <b-button
              size="sm"
              variant="warning"
              v-b-modal.notaERP
              @click="abrirEnvioSapiens(data.item)">
              <v-icon scale="1" name="code" />
            </b-button>
          </template>
        </b-table>
      </div>
    </div>

    <div class="paginacao" v-if="dados.itemProcessamento.length > 0">
      <b-pagination
        class="paginacao__seletor"
        :total-rows="paginacao.totalDePaginas"
        :per-page="paginacao.limitePorPagina"
        v-model="paginacao.paginaAtual"
        @input="mudarPagina"
        size="sm"/>
    </div>

    <!-- <b-modal id="produtos" title="Produtos" size="xl">
            <div>
              <b-table striped hover bordered head-variant="dark" v-if="dados.itemProcessamento" :items="dados.itemProcessamento.retornoProduto" :fields="dados.fieldsProdutos"></b-table>
            </div>
          </b-modal>
          <b-modal id="participantes" title="Participantes" size="xl">
            <div>
              <b-table striped hover bordered head-variant="dark" v-if="dados.itemProcessamento" :items="dados.itemProcessamento.retornosCliente" :fields="dados.fieldsParticipantes"></b-table>
            </div>
    </b-modal>-->

    <b-modal id="notaERP" title="Nota ERP" size="xl">
      <template v-slot:modal-header="{close}">
        <!-- Emulate built in modal footer ok and cancel button actions -->

        <div style="display:-webkit-inline-box;gap:10px !important;">
          <p>Nota ERP</p>
          <b-button variant="danger" size="sm" @click="reprocessarNotaMethod">Reprocessar</b-button>
          <b-button variant="warning" size="sm" v-b-modal.cancelamento style="margin-left:5px !important">Cancelamento</b-button>
        </div>

        <b-button variant="ligth" @click="close()">X</b-button>
      </template>
      <div>          
        <b-button variant="warning" size="sm" @click="showEditor()">{{dados.exibeEditor ? 'Voltar':'Editar'}}</b-button>
        <vue-json-pretty
          :showLine="true"
          :showDoubleQuotes="true"
          :highlightMouseoverNode="true"
          :collapsedOnClickBrackets="true"
          :data="dados.notaERPView"
          v-if="!dados.exibeEditor"></vue-json-pretty>
          
        <b-form-textarea
          id="textarea"
          v-model="dados.notaERPView"
          placeholder="Enter something..."
          rows="3"
          max-rows="15"
          v-if="dados.exibeEditor" @change="dados.notaERPView = JSON.parse(dados.notaERPView)"></b-form-textarea>        
      </div>
    </b-modal>

    <b-modal id="eDocs" title="Resposta E-Docs" size="xl">
      <div>
        <vue-json-pretty
          id="textarea"
          :data="dados.eDocsView"
          rows="3"
          max-rows="20"></vue-json-pretty>
      </div>
      <template v-slot:modal-footer="{ok}">
        <b-button v-if="dados.eDocsView.Codigo == '999'" variant="danger" @click="solicitar_resposta(dados.dadosSolic)">Solicitar novamente</b-button>
        <b-button variant="primary" @click="ok()">OK</b-button>
      </template>
    </b-modal>

    <b-modal id="xml" title="XML de envio" size="xl">
      <div>
        <b-form-textarea
          id="textarea"
          v-model="dados.xmlView"
          rows="3"
          max-rows="20"></b-form-textarea>
      </div>
      <template v-slot:modal-footer="{ok}">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button @click="clipboardSuccessHandler()">Copiar</b-button>
        <b-button variant="primary" @click="ok()">OK</b-button>
      </template>
    </b-modal>

    <b-modal id="cancelamento" title="Cancelamento de nota" size="sm" @ok="cancelarNotaMethod">
      <div>
        <b-form-textarea
          id="textarea"
          v-model="formCancelamento.texto"
          placeholder="Justificativa..."
          rows="3"
          max-rows="20"></b-form-textarea>
      </div>
    </b-modal>
    
    <b-modal id="inutilizacao" title="Inutilização de nota" size="xl" @ok="inutilizacaoNotaMethod">
      <div class="formInutilizacao">
        <InputBasico
          ref="refCNPJ"
          label="CNPJ"
          name="cnpj"
          v-model="formInutilizacao.cnpj"
          min="1"
          max="12"
          class="inputCNPJInutilizacao"
          size="sm"
          :disabled="dados.disabledCampos || dados.checked"/>
        <InputBasico
          ref="refNumini"
          label="Nº Inicial"
          name="Número inicial"
          v-model="formInutilizacao.numIni"
          class="inputNumIni"
          size="sm"
          type="number"
          :disabled="dados.disabledCampos || dados.checked"/>
        <InputBasico
          ref="refNumFinal"
          label="Nº Final"
          name="Número final"
          v-model="formInutilizacao.numFinal"
          class="inputNumFinal"
          size="sm"
          :disabled="dados.disabledCampos || dados.checked"/>
        <InputBasico
          ref="refSerie"
          label="Série"
          name="Série"
          v-model="formInutilizacao.serie"
          class="inputSerieInu"
          size="sm"
          :disabled="dados.disabledCampos || dados.checked"/>
        <InputBasico
          ref="refMotivo"
          label="Motivo da inutilização"
          name="Motivo"
          v-model="formInutilizacao.motivo"
          class="inputMotivo"
          size="sm"
          :disabled="dados.disabledCampos || dados.checked"/>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  getListaNotas,
  getXMLNota,
  reprocessarNota,
  inutilizarNota,
  solicitarResposta
} from "@/common/services/notasService";
import * as dataHelper from "@/common/helpers/DataHelper";
import InputBasico from "@/components/inputs/InputBasico";
import InputSelect from "@/components/inputs/InputSelect";
import VueJsonPretty from "vue-json-pretty";
import convert from "xml-js";
import format from "xml-formatter";
import { getUserObj } from "@/common/localStorage/localStorage";
import { reprocessarNotaXml } from '../../../common/services/notasService';

export default {
  data() {
    return {
      dados: {
        ambiente: process.env.VUE_APP_AMBIENTE ? process.env.VUE_APP_AMBIENTE : "1",
        dataInicial: "",
        dataFinal: "",
        isLoading: true,
        exibeEditor: false,
        roleParam: "",
        itemProcessamento: [],
        fieldsNotas: [
          { key: "tipo", label: "Tipo", sortable: true },
          { key: "eventoNota", label: "Evento", sortable: true },
          { key: "status", label: "Status", sortable: true },
          { key: "numeroNota", label: "Nota", sortable: true },
          { key: "serieNota", label: "Série", sortable: true },
          { key: "CnpjEmissor", label: "CNPJ", sortable: true },
          { key: "xmlGerado", label: "XML", sortable: true },
          { key: "notaSapiens", label: "Nota", sortable: true },
          // { key: "gerarInutilizacao", label: "Inutilizar", sortable: false },
        ],
        notaERPView: "",
        xmlView: "",
        eDocsView: "",
      },
      paginacao: {
        paginaAtual: 1,
        limitePorPagina: 1,
        totalDePaginas: 0,
      },
      form: {
        tipo: "",
        evento: "",
        status: "",
        nota: "",
        serie: "",
        cnpj: "",
      },
      formCancelamento: {
        texto: ""
      },
      formInutilizacao: {
        cnpj: '',
        numIni: '',
        numFinal: '',
        serie: '',
        motivo: '',
        modelo: '55'
      }
    };
  },
  components: {
    InputBasico,
    InputSelect,
    VueJsonPretty,
  },
  created: async function () {
    if (this.$route.query.role) {
      this.dados.roleParam = this.$route.query.role
    }
    await this.getListaProcessamentos();
  },
  sockets: {
    ATUALIZAR_STATUS: async function (data) {
      await this.getListaProcessamentos(false, this.form);
    },
    // HABILITAR_FORM_NOTA: async function (data) {
    //   this.dados.disabledCampos = false
    // },
    // RETORNO_PROCESSAMENTO_FORM_NOTA: async function (data) {
    //   // console.log(data)
    //   this.dados.itemProcessamento = data
    //   this.$validator.reset()
    //   this.errors.clear()
    // }
  },
  methods: {
    showEditor() {
      this.dados.exibeEditor = !this.dados.exibeEditor;

      if (this.dados.exibeEditor) {
        this.dados.notaERPView = JSON.stringify(this.dados.notaERPView)
      } else {
        this.dados.notaERPView = JSON.parse(this.dados.notaERPView)
      }
    },
    retornaDataFormatadaTabelaConsulta(data) {
      return dataHelper.retornaISOParaStringComMascara(data,"DD/MM/YYYY - HH:mm:ss");
    },
    retornaDataFormatadaDiaMesAno(data) {
      return dataHelper.retornaISOParaStringComMascara(data, "DD/MM/YYYY");
    },
    async getListaProcessamentos(runLoad = true, filtro={}) {
      this.dados.isLoading = runLoad;
      let r = (await getListaNotas(0, filtro)).data;
      this.paginacao.totalDePaginas = r.pages;
      this.dados.itemProcessamento = r.list
      this.dados.isLoading = false;
      this.key = !this.key;
    },
    async getXML(nota) {
      this.dados.isLoading = true;
      if (getUserObj().erp.tipo == "INFORLN") {
        this.dados.xmlView = format(nota.xml, {
          indentation: "  ",
          filter: (node) => node.type !== "Comment",
          collapseContent: true,
          lineSeparator: "\n",
        });
      } else {
        let r = (await getXMLNota(nota)).data;
        // this.dados.itemProcessamento.push(...r);
        this.dados.xmlView = format(r.xml, {
          indentation: "  ",
          filter: (node) => node.type !== "Comment",
          collapseContent: true,
          lineSeparator: "\n",
        });
      }
      this.dados.isLoading = false;
      this.key = !this.key;
    },
    getCancelamento(nota, edocs) {
      let cancelamento
      if (getUserObj().erp.tipo == "INFORLN") {
        let xmljs = JSON.parse(convert.xml2json(nota.xml, {compact:true, spaces: 4}));
        if (nota.numnfs) {
          cancelamento = {
            CancelarNfseEnvio: {
              Pedido: {
                InfPedidoCancelamento: {
                  id: nota.docnum,
                  IdentificacaoNfse: {
                    Numero: nota.numnfs,
                    NumeroRps: nota.numnfs,
                    Serie: nota.serie,
                    Cnpj: nota.cnpjFilial,
                    InscricaoMunicipal: xmljs.EnviarLoteRpsEnvio.LoteRps.InscricaoMunicipal._text,
                    CodigoMunicipio: xmljs.EnviarLoteRpsEnvio.LoteRps.ListaRps.Rps.InfRps.Servico.CodigoMunicipio._text
                  },
                  CodigoCancelamento: "1",
                  MotivoCancelamento: this.formCancelamento.texto!=""?this.formCancelamento.texto:"SEM JUSTIFICATIVA DO USUARIO"
                }
              }
            }
          }
        } else {
          cancelamento = {
            IV_EVENT_TYPE: '110111',
            IV_DOCNUM: nota.docnum,
            IV_NFE_ACCESS_KEY: nota.acckey,
            IV_INTERNAL_SEQUENCE_NUMBER: "1",
            IV_AUTHCODE: edocs.ProtocoloAutorizacao,
            IV_TEXT: this.formCancelamento.texto != "" ? this.formCancelamento.texto : "SEM JUSTIFICATIVA DO USUARIO",
            IV_TIMESTAMP: dataHelper.retornaDataFormatadaYYYYMMDDHHmmSS(new Date())
          }
        }
      } else {
        if(nota.EnviarLoteRpsEnvio || nota.CancelarNfseEnvio){
          cancelamento = {
            CancelarNfseEnvio: {
              Pedido: {
                InfPedidoCancelamento: {
                  id: nota.EnviarLoteRpsEnvio.idNota,
                  IdentificacaoNfse: {
                    Numero: nota.EnviarLoteRpsEnvio.LoteRps.ListaRps.Rps.InfRps.IdentificacaoRps.Numero,
                    NumeroRps: nota.EnviarLoteRpsEnvio.LoteRps.ListaRps.Rps.InfRps.IdentificacaoRps.Numero,
                    Serie: nota.EnviarLoteRpsEnvio.LoteRps.ListaRps.Rps.InfRps.IdentificacaoRps.Serie,
                    Cnpj: nota.EnviarLoteRpsEnvio.LoteRps.Cnpj,
                    InscricaoMunicipal: nota.EnviarLoteRpsEnvio.LoteRps.InscricaoMunicipal,
                    CodigoMunicipio: nota.EnviarLoteRpsEnvio.LoteRps.ListaRps.Rps.InfRps.Servico.CodigoMunicipio
                  },
                  CodigoCancelamento: "1",
                  MotivoCancelamento: this.formCancelamento.texto!=""?this.formCancelamento.texto:"SEM JUSTIFICATIVA DO USUARIO"
                }
              }
            }
          }
        } else {
          cancelamento = {
            IV_EVENT_TYPE: '110111',
            IV_DOCNUM: nota.IS_NFE_HEADER.DOCNUM,
            IV_NFE_ACCESS_KEY: nota.IS_NFE_HEADER.ACCESSKEY,
            IV_INTERNAL_SEQUENCE_NUMBER: "1",
            IV_AUTHCODE: edocs.ProtocoloAutorizacao,
            IV_TEXT: this.formCancelamento.texto!=""?this.formCancelamento.texto:"SEM JUSTIFICATIVA DO USUARIO",
            IV_TIMESTAMP: dataHelper.retornaDataFormatadaYYYYMMDDHHmmSS(new Date())
          }
        }
      }
      return cancelamento
    },
    async solicitar_resposta(nota) {
      this.$bvModal.hide("eDocs");
      let retorno = await solicitarResposta(nota);
      this.makeToast("success", retorno.mensagem);
    },
    abrirEnvioSapiens(item) {
      this.dados.notaERPView = item.nota;
      this.dados.eDocsView = item.eDocs;
    },
    abrirEnvioInutilizacao() {
      // this.dados.notaInutilizacaoView = envio;
    },
    clipboardSuccessHandler() {
      document.getElementById("textarea").select();
      document.execCommand("copy");
      this.dados.copiado = "Copiado com sucesso";
    },
    async reprocessarNotaMethod() {
      this.dados.isLoading = true;
      let ret;
      if (getUserObj().erp.tipo == "INFORLN") {
        //Se houve alteração no xml, tenta por aqui
        try {
          //Se houve alteração no xml, tenta por aqui
          ret = (await reprocessarNotaXml(JSON.parse(this.dados.notaERPView))).data;
        } catch (e) {
          //caso contrário irá passar por aqui
          ret = (await reprocessarNotaXml(this.dados.notaERPView)).data;
        }
      } else {
        try {
          //Se houve alteração no xml, tenta por aqui
          ret = (await reprocessarNota(JSON.parse(this.dados.notaERPView))).data;
        } catch (e) {
          //caso contrário irá passar por aqui
          ret = (await reprocessarNota(this.dados.notaERPView)).data;
        }
      }
      if (ret.status == "ERROR") {
        this.makeToast("danger", ret.message);
      }
      this.dados.isLoading = false;
    },
    async cancelarNotaMethod() {
      this.dados.isLoading = true;
      let ret = (await reprocessarNota(this.getCancelamento(this.dados.notaERPView, this.dados.eDocsView))).data;
      if (ret.status == "ERROR") {
        this.makeToast("danger", ret.message);
      }
      this.dados.isLoading = false;
    },
    async inutilizacaoNotaMethod() {
      this.dados.isLoading = true;
      console.log(this.formInutilizacao)
      let xmlNota = this.gerarInutilizacao(this.formInutilizacao)
      console.log(xmlNota)
      let ret = (await inutilizarNota({xml: xmlNota, nota: this.formInutilizacao})).data;
      if (ret.status == "ERROR") {
        this.makeToast("danger", ret.message);
      }
      this.dados.isLoading = false;
    },
    retornaSerie(item) {
      let retorno = ''
      if(item.nota.IS_NFE_IDE)
        retorno = item.nota.IS_NFE_IDE.SERIE

      if(item.nota.IV_SERIE)
        retorno = item.nota.IV_SERIE
      
      if(item.nota.EnviarLoteRpsEnvio)
        retorno = item.nota.EnviarLoteRpsEnvio.LoteRps.ListaRps.Rps.InfRps.IdentificacaoRps.Serie

      if(item.nota.CancelarNfseEnvio)
        retorno = item.nota.CancelarNfseEnvio.Pedido.InfPedidoCancelamento.IdentificacaoNfse.Serie

      if (item.nota.xml) {
        let xmljs = JSON.parse(convert.xml2json(item.nota.xml,{compact:true, spaces: 4}));
        if (xmljs.NFe) {
          retorno = xmljs.NFe.infNFe.ide.serie._text;
        } else if (xmljs.EnviarLoteRpsEnvio) {
          retorno = xmljs.EnviarLoteRpsEnvio.LoteRps.ListaRps.Rps.InfRps.IdentificacaoRps.Serie._text; 
        } else if (xmljs.CancelarNfseEnvio) {
          retorno = xmljs.CancelarNfseEnvio.Pedido.InfPedidoCancelamento._attributes.id.substr(24,3); 
        }
      }

      if (item.nota && item.nota.acckey && retorno == '')
        retorno = item.nota.acckey.substr(22, 3)
        
      return retorno
    },
    retornaNota(item) {
      let retorno = ''
      if(item.nota.IS_NFE_IDE)
        retorno = item.nota.IS_NFE_IDE.N_NF

      if(item.nota.IV_N_NF)
        retorno = item.nota.IV_N_NF
      
      if(item.nota.EnviarLoteRpsEnvio)
        retorno = item.nota.EnviarLoteRpsEnvio.LoteRps.ListaRps.Rps.InfRps.IdentificacaoRps.Numero

      if(item.nota.CancelarNfseEnvio)
        retorno = item.nota.CancelarNfseEnvio.Pedido.InfPedidoCancelamento.IdentificacaoNfse.Numero

      if (item.nota.xml) {
        let xmljs = JSON.parse(convert.xml2json(item.nota.xml,{compact:true, spaces: 4}));
        if (xmljs.NFe) {
          retorno = xmljs.NFe.infNFe.ide.nNF._text;
        } else if (xmljs.EnviarLoteRpsEnvio) {
          retorno = xmljs.EnviarLoteRpsEnvio.LoteRps.ListaRps.Rps.InfRps.IdentificacaoRps.Numero._text; 
        } else if (xmljs.CancelarNfseEnvio) {
          retorno = xmljs.CancelarNfseEnvio.Pedido.InfPedidoCancelamento.IdentificacaoNfse.Numero._text; 
        }
      }

      if (item.nota && item.nota.acckey && retorno == '')
        retorno = item.nota.acckey.substr(25, 9)

      return retorno
    },
    makeToast(variant = null, message) {
      this.$bvToast.toast(JSON.stringify(message), {
        title: `Erro ao processar a nota`,
        variant: variant,
        solid: true,
      });
    },
    async mudarPagina() {
      this.dados.isLoading = true;
      this.getApiMudancaPagina()
        .then((res) => {
          this.dados.itemProcessamento = res.list;
          this.dados.isLoading = false;
        })
        .catch((err) => {
          console.log(err)
          this.limparLista();
          // this.tratarRespostaMsg(err);
        });
    },
    async getApiMudancaPagina() {
      const { paginaAtual } = this.paginacao;
      return (await getListaNotas(paginaAtual, this.form)).data;
    },
    mostrarRespostaEdocs(a) {
      //Caso ocorra erro de integração, mostra o erro, senão mostra retorno do EDocs
      this.dados.eDocsView = a.erro_cdi ? "Erro CDI: " + a.erro_cdi : a.eDocs;
      this.$bvModal.show("eDocs");
    },
    limparLista(){
      this.dados.itemProcessamento = []
    },
    getTipo() {
      return [
        { text: "Produto", value: "PRODUTO" },
        { text: "Serviço", value: "SERVICO" },
      ];
    },
    getEvento() {
      return [
        { text: "Emissão", value: "EMISSAO" },
        { text: "Cancelamento", value: "CANCELAMENTO" },
        { text: "Inutilização", value: "INUTILIZACAO" },
      ];
    },
    getStatus() {
      return [
        { text: "Em processamento", value: "EM_PROCESSAMENTO" },
        { text: "Erro de processamento", value: "ERRO_PROCESSAMENTO" },
        { text: "Processada", value: "PROCESSADA" },
      ];
    },
    gerarInutilizacao(form) {
      let vNfe = ''
      let dNow = new Date();
      let vaAno = ("00" + dNow.getFullYear()).slice(-2);
      let vaMes = ("00" + dNow.getMonth()).slice(-2);
      let vaDia = ("00" + dNow.getDay()).slice(-2);
      let vaHora = ("00" + dNow.getHours()).slice(-2);
      let vaMinuto = ("00" + dNow.getMinutes()).slice(-2);
      let vaSegundo = ("00" + dNow.getSeconds()).slice(-2);
      let filial = getUserObj().filiais.filter(f => f.codFilial == form.cnpj || f.cnpj == form.cnpj)[0];

      let ACCKEY =
        filial.codUfSefaz +
        vaAno +
        ("00000000000000" + form.cnpj).slice(-14) +
        form.modelo +
        ("000" + form.serie).slice(-3) +
        ("000000000" + form.numIni).slice(-9) +
        ("000000000" + form.numFinal).slice(-9);

      vNfe = '<inutNFe xmlns="http://www.portalfiscal.inf.br/nfe" versao="4.00">';
      vNfe = vNfe + '<infInut Id="ID' + ACCKEY + '">';
      vNfe = vNfe + "<tpAmb>" + this.dados.ambiente + "</tpAmb>"; //1 - Produção / 2 - Homologação
      vNfe = vNfe + "<xServ>INUTILIZAR</xServ>";
      vNfe = vNfe + "<cUF>" + filial.codUfSefaz + "</cUF>";
      vNfe = vNfe + "<ano>" + vaAno + "</ano>";
      vNfe = vNfe + "<CNPJ>" + form.cnpj + "</CNPJ>";
      vNfe = vNfe + "<mod>" + form.modelo + "</mod>";
      vNfe = vNfe + "<serie>" + parseInt(form.serie) + "</serie>";
      vNfe = vNfe + "<nNFIni>" + parseInt(form.numIni) + "</nNFIni>";
      vNfe = vNfe + "<nNFFin>" + parseInt(form.numFinal) + "</nNFFin>";
      vNfe = vNfe + "<xJust>" + form.motivo + "</xJust>";
      vNfe = vNfe + "</infInut>";
      vNfe = vNfe + "</inutNFe>";
      return vNfe
    },
    limparFiltro(){
      this.form = {
        tipo: "",
        evento: "",
        status: "",
        nota: "",
        serie: "",
        cnpj: "",
      }
    },
    async buscarFiltro(){
      await this.getListaProcessamentos(true, this.form)
    },
    validaRole(){
      // return ['admin','gerente','tecnico'].indexOf(this.dados.roleParam) >= 0
      return getUserObj().usuario.admin;
    },
    rowClass(item, type) {      
      if (item && type === 'row') {
        if (item.erro_cdi) {
          return "table-danger"
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/common/scss/breakpoints.scss";

.filtro {
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: $medium-size) {
    width: 100%;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 0.8em;
  }

  //   @media (min-width: $extra-large-size) {
  //     width: $medium-size;
  //   }
}


.formInutilizacao {
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: $medium-size) {
    width: 100%;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 0.8em;
  }

  //   @media (min-width: $extra-large-size) {
  //     width: $medium-size;
  //   }
}

.input {
  &Tipo {
    @media (min-width: $medium-size) {
      grid-column: 1 / 3;
    }
  }

  &Evento {
    @media (min-width: $medium-size) {
      grid-column: 3 / 5;
    }
  }

  &Status {
    @media (min-width: $medium-size) {
      grid-column: 5 / 7;
    }
  }
  &Nota {
    @media (min-width: $medium-size) {
      grid-column: 7 / 9;
    }
  }
  &Serie {
    @media (min-width: $medium-size) {
      grid-column: 9 / 10;
    }
  }
  &CNPJ {
    @media (min-width: $medium-size) {
      grid-column: 10 / -1;
    }
  }
  &CNPJInutilizacao {
    @media (min-width: $medium-size) {
      grid-column: 1 / 6;
    }
  }
  &NumIni {
    @media (min-width: $medium-size) {
      grid-column:6 / 9;
    }
  }
  &NumFinal {
    @media (min-width: $medium-size) {
      grid-column: 9 / -1;
    }
  }
  &SerieInu {
    @media (min-width: $medium-size) {
      grid-column: 1 / 4;
    }
  }
  &Motivo {
    @media (min-width: $medium-size) {
      grid-column: 4 / -1;
    }
  }
}

.btn {
  &Processar {
    @media (min-width: $medium-size) {
      grid-column: 1 / 4;
      margin-top: 1.9rem;
      margin-bottom: 1.9rem;
    }
  }
}

.listaNotas {
  @media (min-width: $medium-size) {
    grid-column: 1 / -1;
  }
}

.concluido {
  @media (min-width: $medium-size) {
    grid-column: 4 / -1;
    align-self: center;
    margin-left: 1.5rem;
  }
}

.loader {
  @media (min-width: $medium-size) {
    grid-column: 1 / -1;
    align-self: center;
  }
  &Spinner {
    align-self: center;
  }
  &Check {
    color: #00cf2d;
  }
}
.notaCard {
  @media (min-width: $medium-size) {
    grid-column: 1 / 6;
    padding-bottom: 1.5rem;
  }
}

.parametrizacao {
  margin: 0;
}

.paginacao {
  display: flex;
  justify-content: flex-end;

  &__seletor {
    margin: 0;
  }
}
</style>